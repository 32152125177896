import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Link from '@src/components/Link';
import AppBarOffset from '@src/components/AppBarOffset';
import LoginForm from '@src/components/forms/LoginForm'
import Paper from '@material-ui/core/Paper';
import Image from 'next/image';
import Grid from '@material-ui/core/Grid';

import AutoRedirectTop from "@src/components/AutoRedirectTop";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    joyrideZIndex: {
      zIndex: theme.zIndex.drawer + 100,
    }
  }),
);

export default function Index() {
  const classes = useStyles();

  return (
    <AutoRedirectTop>
    <div className={classes.root}>
      <main className={classes.content}>
        <AppBarOffset />
        <Container maxWidth="xs">
          <Box>
            <Paper square style={{padding: "20px"}} elevation={2}>
          <Image
            src="/images/logo.png"
            alt="adlog logo"
            width={596}
            height={425}
          />
          <Typography component="h1" variant="h4" align="center">
            ログイン
          </Typography>
            <LoginForm />
          <Grid container>
            <Grid item xs>
              <Link href="./password/reset" variant="body2">
                パスワードを忘れた場合
              </Link>
            </Grid>
          </Grid>
        </Paper>
          </Box>
        </Container>
      </main>
    </div>
    </AutoRedirectTop>
  );
}

