import { Formik, Form, Field } from 'formik';
import { Button, LinearProgress } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import PasswordField from '@src/components/forms/PasswordField'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useAppDispatch } from "@src/redux/store";
import { login } from "@src/redux/slices/authSlice";
import { useSnackbar } from 'notistack';
import { unwrapResult } from '@reduxjs/toolkit';
import { errorSnackbarOption, successSnackbarOption } from '@src/utils/snackbar';

interface Values {
  email: string;
  password: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      marginTop: theme.spacing(3)
    },
    submit: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3)
    }
  })
)

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('無効なメールアドレスです')
    .required('この項目は必須です'),
  password: Yup.string()
    .required('この項目は必須です'),
})

export default function LoginForm() {
  const classes = useStyles();
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      } as Values}
      validationSchema={LoginSchema}
      onSubmit={(values, { setSubmitting }) => {
        dispatch(login({email: values.email, password: values.password}))
        .then(unwrapResult)
        .then((_response) => {
          enqueueSnackbar(`ログインしました。`, successSnackbarOption)
        })
        .catch((_error) => {
          enqueueSnackbar(`ログインに失敗しました。`, errorSnackbarOption)
        })
        .finally(
          () => {
            setSubmitting(false);
          }
        )
        /*
        setTimeout(() => {
          // alert(JSON.stringify(values, null, 2));
        }, 500);
        */
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <Field
            component={TextField}
            name="email"
            type="email"
            label="メールアドレス"
            fullWidth
            className={classes.input}
          />
          <br />
          <Field
            component={PasswordField}
            type="password"
            label="パスワード"
            name="password"
            fullWidth
            className={classes.input}
          />
          {isSubmitting && <LinearProgress />}
          <br />
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            onClick={submitForm}
            fullWidth
            className={classes.submit}
          >
            ログイン
          </Button>
        </Form>
      )}
    </Formik>
  );
}